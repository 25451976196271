.container {
  padding: 1em;
}

.container ul {
  list-style: circle;
  padding-left: 2em;
}

.container ol {
  list-style: decimal;
  padding-left: 2em;
}
