.pluginContainer {
  align-items: center;
  display: flex;
}

.pluginComponentContainer {
  flex: 1;
}

.checkboxContainer {
  margin-right: 10px;
}

.unchecked {
  background: #54698D;
  border-radius: 100px;
  height: 32px;
  width: 32px;
}
