.stepsHeader {
  align-items: center;
  display: flex;
}

.stepsTitle {
  align-items: center;
  display: flex;
  flex: 2;
  padding-left: 1em;
  height: 32px;
}

.stepsIndicator {
  flex: 1;
  text-align: right;
  padding-right: 1em;
  white-space: nowrap;
}

.stepsBulletContainer {
  cursor: pointer;
  display: inline-block;
}

.blankSpace {
  width: 1.25rem;
}
