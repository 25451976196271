.container {
  padding: 1em 1em 1em 1em;
}

.documentContainer {
  margin-right: 1em;
}

.wrapper {
  align-items: center;
}

.deleteButton {
  background-color: #54698d;
  border-radius: 50px;
}

.loadingContainer {
  position: relative;
  height: 32px;
  width: 32px;
}

.itemLabel {
  word-break: break-all;
}

.actionsContainer {
  display: flex;
  text-align: center;
  flex: 1;
  flex-wrap: wrap;
  flex-basis: 100%;
}

.actionAlignLeft {
  justify-content: flex-start;
}

.actionAlignCenter {
  justify-content: center;
}

.actionAlignRight {
  justify-content: flex-end;
}

.actionItem {
  margin-right: 1em;
  white-space: nowrap;
  text-align: center;
}

.actionText {
  word-break: break-all;
}

.esignFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sigPad {
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 2;
}

.eSignModal {
  width: auto;
}

.eSignModalContent {
  position: relative;
}

.sigPadOuterContainer {
  padding-top: 80%;
}

.sigPadContainer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.sigPadBackground {
  align-items: center;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  user-select: none;
  z-index: 1;

  .sigPadSignHere {
    color: #ccc;
    font-size: 24px;
  }

  .sigPadMessage {
    align-items: center;
    bottom: 0;
    color: #bbb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 100%;
    margin-bottom: 25px;
  }

  .sigPadUnderlineContainer {
    width: 100%;
    text-align: center;

    span {
      display: inline-block;
      font-size: 18px;
    }
  }

  .sigPadUnderline {
    border-bottom: 1px solid #bbb;
    height: 100%;
    margin-left: 10px;
    width: calc(100% - 25px);
  }

  .sigPadTerms {
    min-height: 40px;
    text-align: center;
  }
}

.sigPadTerms {
  font-size: 0.75em;
}

.column {
  width: auto;
}

.canvasClearButton {
  position: absolute;
  right: 15px;
  top: 5px;
  z-index: 2;
}

.esignTabsContainer {
  height: 100%;
  padding: 10px 20px 0;

  :global {
    .slds-tabs_default__content {
      height: calc(100% - 40px);
      padding: 0;
    }
  }

  .signatureTextContainer {
    box-sizing: border-box;
    padding: 1em 0;
    height: 100%;
  }

  .signatureTextAreaBorder {
    border: 1px solid #dddbda;
    font-family: 'Dawning of a New Day';
    white-space: nowrap;
    overflow: auto;
  }

  .signatureTextArea {
    font-size: 72px;
    padding: 0 20px;
    text-align: left;
  }
}

.pdfContainer {
  background: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  z-index: 8000; // why

  .pdfHeaderContainer {
    align-items: center;
    display: flex;
    padding: 10px;
    justify-content: space-between;
  }

  .pdfDownloadContainer {
    position: relative;

    button {
      outline: none;
      box-shadow: none;
    }
  }

  .pdfCloseButton {
    background: transparent;
    border: 0;
  }

  .pdfPreviewIframe {
    border: 0;
    width: 100%;
    height: calc(100% - 64px);
  }
}

// @HACK
@media (max-width: 639px) {
  .actionsContainer {
    :global {
      & .slds-button + .slds-button {
        margin-left: 0 !important;
      }

      & .slds-button,
      & .slds-text-link {
        margin-right: 0.75em !important;
      }
    }
  }
}

@media (min-width: 640px) {
  .documentContainer {
    margin-right: 5em;
  }

  .actionsContainer {
    text-align: left;
    flex-basis: auto;
  }

  .column {
    width: auto;
  }

  .sigPadTerms {
    font-size: 1em;
  }
}

@media (min-width: 470px) {
  .sigPadOuterContainer {
    padding: 30%;
  }
}

@media (min-width: 48em) {
  // @HACK: Had to use !important here because of the deep css selectors used by the slds modal's default css rules
  .eSignModal {
    width: 640px !important;
    max-width: 640px !important;
    min-width: 640px !important;
  }

  .eSignModalContent {
    width: 640px;
  }

  .sigPadOuterContainer {
    height: 206px;
    padding: 0;
  }

  .sigPadContainer {
    position: relative;
    height: 100%;
    width: 100%;
  }

  .sigPadTerms {
    min-height: 40px;
  }

  .column {
    width: auto;
  }
}
