.chatContainer {
  padding-left: 0.75em;
}

.floatingWrapper {
  margin: auto;
  max-width: 380px;
  padding: 140px 0 0 0;
}

.floatingContainer {
  background-color: white;
  color: rgb(22, 50, 92);
  margin: 0px auto;
  padding: 1.25rem;
  border-radius: 0.25rem;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(216, 221, 230);
  border-image: initial;
  position: relative;
}
