.previewContainer {
  background-size: contain;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}

.relative {
  position: relative;
}

.nowrap {
  white-space: nowrap;
}
