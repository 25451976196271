.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.placeholderContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center;
  max-width: 150px;
}
