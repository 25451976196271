// From Stripe...
.StripeElement {
  box-sizing: border-box;
  height: 40px;
  padding: 10px 12px;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 3px 0 #5a5a5a;
  transition: box-shadow 150ms ease;
}

.payment-form {
  background-color: transparent;
}

.payment-form .slds-tabs_default__item {
  padding-bottom: 5px;

  .slds-tabs_default__link {
    white-space: normal;
    min-height: 2rem;
    max-height: unset;
    height: unset;
    line-height: 1.5rem;
    text-align: center;
  }
}
