.contentContainer {
  display: flex;
}

.pluginContainer {
  flex: 1;
}

.formContainer {
  display: block;
  padding: 1em 0;
  width: 370px;
}
