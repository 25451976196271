@import '~@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system.css';
@import '~@salesforce-ux/design-system/assets/styles/salesforce-lightning-design-system_touch.css';

@font-face {
  font-family: 'Dawning of a New Day';
  src: url('./fonts/dawningofanewday.ttf');
}

@font-face {
  font-family: 'Debby';
  src: url('./fonts/debby.ttf');
}

@font-face {
  font-family: 'Flanella';
  src: url('./fonts/flanella.ttf');
}

@font-face {
  font-family: 'Good Vibes';
  src: url('./fonts/goodvibes.ttf');
}

@font-face {
  font-family: 'Variane Scripts';
  src: url('./fonts/varianescript.ttf');
}

/**
 * Salesforce overrides
 **/
#root .slds-notify_toast {
  min-width: calc(100% - 1rem);
}
